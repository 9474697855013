import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Logger} from 'common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AbstractDao} from '../../../common/model/abstract.dao';

import {FormService} from './form/form.service';
import {addGeolocationHeaders} from '../../../common/http/http';

export class PlayDao extends AbstractDao {
  /**
   * Used to calculate interval time between two play requests
   */
  protected measuringPlayTime: number;

  constructor(protected http: HttpClient, protected logger: Logger) {
    super();
  }

  play(
    data: FormService,
    extras?: {geolocation: GeolocationPosition},
  ): Observable<any> {
    this.measurePlayTime();

    let headers = new HttpHeaders();

    if (extras?.geolocation) {
      headers = addGeolocationHeaders(headers, extras.geolocation);
    }

    if (data.form.value.promo) {
      return this.playPromo(data.toBackend(), headers);
    } else {
      return this.http.post(
        this.baseUrl + '/users/play/descriptor',
        JSON.stringify(data.toBackend()),
        {headers: headers},
      );
    }
  }

  playScanned(code: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/play/scannercode`, {code});
  }

  saveUnfinishedPlay(formService: FormService): Observable<void> {
    return this.http
      .post(
        this.baseUrl + '/users/unfinish/play/descriptor',
        JSON.stringify(formService.toBackend()),
      )
      .pipe(map(() => void 0));
  }

  protected playPromo(
    data: Record<string, unknown>,
    headers?: HttpHeaders,
  ): Observable<any> {
    this.measurePlayTime();
    return this.http.post(
      this.baseUrl + '/users/prereserves/play/descriptor',
      JSON.stringify(data),
      {headers: headers},
    );
  }

  protected measurePlayTime() {
    if (!this.measuringPlayTime) {
      this.measuringPlayTime = Date.now();
    } else {
      const lastTime = this.measuringPlayTime;
      this.measuringPlayTime = Date.now();

      const diffTime = this.measuringPlayTime - lastTime;
      if (diffTime < 1000) {
        this.logger.warn('Duplicate play request in ' + diffTime.toString());
      }
    }
  }
}
