import {Inject, Injectable} from '@angular/core';
import {Logger} from 'common';
import {map} from 'rxjs';
import {filter, first, switchMap} from 'rxjs/operators';

import {MeasuringService} from '../marketing/measuring.service';
import {SessionService} from '../user/auth/session.service';

@Injectable({providedIn: 'root'})
export class PwaInstallService {
  savedEvent: any;

  promptOutcome = '';

  constructor(
    private logger: Logger,
    private measuringService: MeasuringService,
    private sessionService: SessionService,
    @Inject('window') private window: Window,
  ) {}

  /**
   * Set up event listeners for the prompt and installation
   */
  initialize() {
    this.window.addEventListener('beforeinstallprompt', event => {
      // this event can be delayed if chrome tried to show it and we want it a
      // bit later maybe after the user finishes the registration process. TODO
      // maybe make this conditional based on current route??? does it even
      // make sense with closed app???
      event.preventDefault();
      this.savedEvent = event;
      return false;
    });

    this.window.addEventListener('appinstalled', () => {
      this.sendBackendEventIfLoggedin();
    });
  }

  /**
   * If the browser tried to show the native install prompt and was saved, this
   * will show it now. The message can only be shown once if saved.
   */
  promptInstallIfSaved(): boolean {
    if (this.savedEvent) {
      this.savedEvent.prompt(); // trigger the dialog
      this.savedEvent = null;

      return true;
    } else {
      this.logger.error('Se ha lanza el prompt de instalación pero no hay evento.');
      return false;
    }
  }

  /**
   * Check if prompt event is available to launch.
   */
  isPromptAvailable() {
    return !!this.savedEvent;
  }

  /**
   * Check whether or not the pwa is running from the homescreen.
   */
  isInstalled() {
    return this.window.matchMedia('(display-mode: standalone)').matches;
  }

  // TODO · LOW · Move event logic to pwaInstallPromptService
  sendEventIfInstalled() {
    if (this.isInstalled()) {
      this.sendBackendEventIfLoggedin();
    }
  }

  private sendBackendEventIfLoggedin() {
    this.sessionService
      .isLoggedIn()
      .pipe(
        first(),
        filter(isLoggedIn => isLoggedIn),
        switchMap(() => this.measuringService.sendBackendEvent('pwa_install')),
        map(() => this.measuringService.sendTagManagerEvent('pwa_install')),
      )
      .subscribe();
  }
}
